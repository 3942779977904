<section class="recording-container">
  <!-- <div #recordings style="margin: 1rem 0"></div> -->

  <img class="d-none d-md-block" src="assets/images/transparent-bg-lg.png" alt="transparent-bg">
  <img class="d-md-none" src="assets/images/transparent-bg-sm.png" alt="transparent-bg">

  <div class="recorder">
    <div class="wave-form" #mic></div>
    <p #progress>00:00</p>
    <button type="button" (click)="stopRecording()"></button>
  </div>
</section>
