import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as htmlToImage from 'html-to-image';
import { Task } from 'src/app/core/models/domain.model';

@Component({
  selector: 'app-summarized-points',
  templateUrl: './summarized-points.component.html',
  styleUrls: ['./summarized-points.component.scss'],
})
export class SummarizedPointsComponent {
  @Input() tasks: Task[] = [];
  @Input() isLoading: boolean = false;
  public isSharing: boolean = false;
  public insights: any[] = [];
  private file!: File;
  constructor(public activeModal: NgbActiveModal, private cdr: ChangeDetectorRef) {
    setTimeout(() => {
      this.insights = [
        ...this.tasks.filter(t => t.flag === 'negative').map((t, i, items) => ({ flag: t.flag, count: items.length, label: 'Issues' })).filter((_, i, items) => items.length <= 1 || i === 0),
        ...this.tasks.filter(t => t.flag === 'positive').map((t, i, items) => ({ flag: t.flag, count: items.length, label: 'Progress' })).filter((_, i, items) => items.length <= 1 || i === 0),
        ...this.tasks.filter(t => t.flag === 'todo').map((t, i, items) => ({ flag: t.flag, count: items.length, label: 'To-Do' })).filter((_, i, items) => items.length <= 1 || i === 0)
      ];
    }, 0);
  }

  public share() {
    this.isSharing = true;
    if (!this.file) {
      htmlToImage.toBlob(document.getElementById('scroll-area')!)
        .then(async (blob) => {
          this.isSharing = false;
          this.file = new File([blob!], 'Summarized Points.png', { type: blob!.type, lastModified: Date.now() });
          const files = [this.file] as any;
          this.sendFileToMedia(files);
        });
    }
  }

  private async sendFileToMedia(files: any) {
    if (files?.length === 0) {
      return;
    }
    if (navigator.share) {
      navigator.share({
        files,
        title: "Images",
        text: "Summarized Points",
      })
        .then(() => {
          console.log('Share successful');
          this.isSharing = false;
        })
        .catch((error) => {
          this.isSharing = false;
          console.error('Share failed:', error)
        });
    } else {
      console.error('Web Share API is not supported in this browser');
      this.isSharing = false;
    }
  }
}
