<div class="summary-container">
  <img class="close-btn" src="assets/images/icons/close.png" alt="close.png" (click)="activeModal.close('Click')">
  <div class="heading">
    <img src="assets/images/icons/star-dark.png" alt="star.png">
    <h5>Summarized Points</h5>
  </div>
  <!-- <p class="description">
    10 points summarized from your voice record
  </p> -->
  <div class="insight-container" *ngIf="!isLoading">
    <div *ngFor="let insight of insights;">
      <div class="item">
        <div class="status" [ngClass]="insight.flag"></div>
        <div class="content">{{insight.count}} {{insight.label}}</div>
      </div>
    </div>
  </div>
  <div class=" scroll-container">
    <img *ngIf="isLoading" class="loading" src="assets/gifs/spinner.gif" alt="loader.gif">
    <div class="scroll-area" id="scroll-area" *ngIf="!isLoading">
      <div class="cards-container" id="cardsContainer">
        <div class="card" *ngFor="let task of tasks">
          <div class="text">{{task.description}}</div>
          <div class="status"
            [ngClass]="{'success':task.flag==='positive' || task.flag==='todo','issue':task.flag==='negative','neutral':task.flag==='neutral'}">
            {{task.flag==='positive'?'Progress':task.flag==='todo'?'To-Do':task.flag==='neutral'?'Neutral': 'Issue'}}
            <img
              [ngClass]="{'insight':task.flag==='positive','warning':task.flag==='negative' ,'neutral':task.flag==='neutral','todo':task.flag==='todo'}"
              [src]="task.flag==='positive'?'assets/images/icons/insight-dark.png':task.flag==='neutral'?'assets/images/icons/neutral.svg':task.flag==='todo'?'assets/images/icons/todo.svg':'assets/images/icons/warning-dark.png'"
              alt="icon.png">
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="footer-section text-center" *ngIf="!isLoading">
    <a href="javascript:void(0)" class="zaper-btn summary-btn" role="button" aria-label="Share" id="share"
      (click)="share()">
      <ng-container *ngIf="!isSharing">
        <span class="btn-icon"></span>
        <span>SHARE</span>
      </ng-container>

      <div class="spinner-border text-light" role="status" *ngIf="isSharing">
        <span class="sr-only">Loading...</span>
      </div>
    </a>
  </p>
</div>