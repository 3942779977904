import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import WaveSurfer from 'wavesurfer.js';
// @ts-ignore
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js';

@Component({
  selector: 'app-voice-recorder',
  templateUrl: './voice-recorder.component.html',
  styleUrls: ['./voice-recorder.component.scss']
})
export class VoiceRecorderComponent {

  @ViewChild('mic', { static: true }) mic!: ElementRef;
  @ViewChild('recordings', { static: true }) recordings!: ElementRef;
  @ViewChild('progress', { static: true }) progress!: ElementRef;

  public wavesurfer!: WaveSurfer;
  public record!: any;
  public scrollingWaveform = true;
  public continuousWaveform = false;
  public defaultDeviceId: string | null = null;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.createWaveSurfer();
    this.initializeAudioDevices();
    this.onRecord();
  }

  createWaveSurfer() {
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }

    this.wavesurfer = WaveSurfer.create({
      container: this.mic.nativeElement,
      barGap: 15,
      barRadius: 10,
      barWidth: 10,
      // barHeight:1,
      waveColor: 'white',
      progressColor: 'white',
    });

    this.record = this.wavesurfer.registerPlugin(
      RecordPlugin.create({
        renderRecordedAudio: false,
        scrollingWaveform: this.scrollingWaveform,
        continuousWaveform: this.continuousWaveform,
        continuousWaveformDuration: 30,
      })
    );

    this.record.on('record-end', (blob: Blob) => this.onRecordEnd(blob));
    this.record.on('record-progress', (time: number) => this.updateProgress(time));
  }

  updateProgress(time: number) {
    const formattedTime = [
      Math.floor((time % 3600000) / 60000),
      Math.floor((time % 60000) / 1000),
    ]
      .map((v) => (v < 10 ? '0' + v : v))
      .join(':');

    this.progress.nativeElement.textContent = formattedTime;
  }

  // handleRecordEnd(blob: Blob) {
  //   const recordedUrl = URL.createObjectURL(blob);
  //   const wavesurfer = WaveSurfer.create({
  //     container: this.recordings.nativeElement,
  //     waveColor: 'rgb(200, 100, 0)',
  //     progressColor: 'rgb(100, 50, 0)',
  //     url: recordedUrl,
  //   });

  //   const button = document.createElement('button');
  //   button.textContent = 'Play';
  //   button.onclick = () => wavesurfer.playPause();
  //   wavesurfer.on('pause', () => (button.textContent = 'Play'));
  //   wavesurfer.on('play', () => (button.textContent = 'Pause'));

  //   const link = document.createElement('a');
  //   Object.assign(link, {
  //     href: recordedUrl,
  //     download: `recording.${blob.type.split(';')[0].split('/')[1] || 'webm'}`,
  //     textContent: 'Download recording',
  //   });

  //   this.recordings.nativeElement.appendChild(button);
  //   this.recordings.nativeElement.appendChild(link);
  // }

  initializeAudioDevices() {
    RecordPlugin.getAvailableAudioDevices().then((devices: any[]) => {
      if (devices.length > 0) {
        this.defaultDeviceId = devices[0].deviceId; // Use the first available device as default
      }
    });
  }

  onRecord() {
    if (this.record.isRecording() || this.record.isPaused()) {
      this.record.stopRecording();
      return;
    }
    this.record.startRecording({ deviceId: this.defaultDeviceId }).then(() => { });
  }

  onPauseClick() {
    if (this.record.isPaused()) {
      this.record.resumeRecording();
      return;
    }
    this.record.pauseRecording();
  }

  public stopRecording() {
    this.record.stopRecording();
  }

  public onRecordEnd(blob: Blob) {
    const file = new File([blob], 'recording.mp3', { type: blob.type });
    this.activeModal.close({success:true,payload:file});
  }

}
